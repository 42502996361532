@import "~/frontend/scss/stage0";

$primary: #03a64a;
$secondary: #edef1e;
$alternative: #495057;
$enable-gradients: true;
$gradient: linear-gradient(180deg, rgba(#000, 0), rgba(#000, 0.2)) !default;
$min-contrast-ratio: 3.2;

@import "~/frontend/scss/stage1";

// dropdown
$dropdown-dark-bg: $secondary;
$dropdown-dark-link-color: $dark;
$dropdown-dark-link-hover-color: $dark;
// navbar
$navbar-light-toggler-border-color: $light;
$navbar-dark-toggler-border-color: $dark;
// forms
$dealersite-form-color: $dark;

@import "~/frontend/scss/stage2";
